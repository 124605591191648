import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  SurveyData,
  UserMutators,
} from "../gen/rbt/cloud/v1alpha1/user/user_rbt_react";

// TODO(riley): use protobuf as a schema rather than zod.
const formSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  companyName: z.string(),
  aboutYourself: z.string(),
  preferredProgrammingLanguages: z.string(),
  useCase: z.string(),
});

export const SignUpForm = ({
  mutators,
  surveyData,
}: {
  mutators: UserMutators;
  surveyData: SurveyData | undefined;
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      aboutYourself: "",
      preferredProgrammingLanguages: "",
      useCase: "",
    },
    values: surveyData,
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutators.setSurveyData({ surveyData: values });
  }

  return (
    <div className="w-[400px]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="First name*" required {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Last name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="companyName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Company name*" required {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="preferredProgrammingLanguages"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="What are your preferred programming languages?"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="useCase"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="What do you want to build with Reboot?"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="aboutYourself"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Please share a bit more about yourself (LinkedIn, X, etc)"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="bg-secondary hover:bg-accent focus:bg-accent text-primary w-64"
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
};
