import { cn } from "@/lib/utils";

export const TwoColumnLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="container flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      {children}
    </div>
  );
};

export const Column = ({
  children,
  left = false,
  right = false,
}: {
  children: React.ReactNode;
  left: boolean;
  right: boolean;
}) => {
  if (left == right) {
    // TODO: remove this constraint with better CSS. The position of the element
    // in document should dictate which side is which.
    throw new Error("Columns must be specified 'right' or 'left'");
  }
  return (
    <div className="flex-1 h-screen">
      <div className="h-screen flex items-center justify-center">
        <div className={cn("relative", left ? "ml-auto" : "mr-auto")}>
          {children}
        </div>
      </div>
    </div>
  );
};

Column.defaultProps = { left: false, right: false };
