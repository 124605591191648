// This file was copied and modified from:
// https://github.com/ory/elements/blob/main/examples/react-spa/src/Login.tsx
// Ory custom UI elements documentation:
// https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-ory-elements

import { LoginFlow, UpdateLoginFlowBody } from "@ory/client";
import { UserAuthCard } from "@ory/elements";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthSession } from ".";
import { sdk, sdkError } from "./ory-sdk";

export const Login = (): JSX.Element => {
  const [flow, setFlow] = useState<LoginFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { rerunAuthToSession } = useAuthSession();

  const aal2 = searchParams.get("aal2");
  const loginChallenge = searchParams.get("login_challenge");
  const returnTo = searchParams.get("return_to");

  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, "/login", true);

  const createFlow = () => {
    sdk
      .createBrowserLoginFlow({
        refresh: true,
        aal: aal2 ? "aal2" : "aal1",
        ...(loginChallenge && { loginChallenge: loginChallenge }),
        ...(returnTo && { returnTo: returnTo }),
      })
      .then(({ data: flow }) => {
        setSearchParams({ ["flow"]: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateLoginFlowBody) => {
    if (!flow) return navigate("/login", { replace: true });

    sdk
      .updateLoginFlow({ flow: flow.id, updateLoginFlowBody: body })
      .then(() => {
        rerunAuthToSession();
        navigate("/", { replace: true });
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    const flowId = searchParams.get("flow");
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }

    createFlow();
  }, []);

  return flow ? (
    <UserAuthCard
      flowType={"login"}
      flow={flow}
      className="py-0"
      additionalProps={{
        forgotPasswordURL: {
          handler: () => {
            const search = new URLSearchParams();
            flow.return_to && search.set("return_to", flow.return_to);
            navigate(
              {
                pathname: "/recovery",
                search: search.toString(),
              },
              { replace: true }
            );
          },
        },
        signupURL: {
          handler: () => {
            const search = new URLSearchParams();
            flow.return_to && search.set("return_to", flow.return_to);
            flow.oauth2_login_challenge &&
              search.set("login_challenge", flow.oauth2_login_challenge);
            navigate(
              {
                pathname: "/signup",
                search: search.toString(),
              },
              { replace: true }
            );
          },
        },
      }}
      includeScripts={true}
      onSubmit={({ body }) => submitFlow(body as UpdateLoginFlowBody)}
    />
  ) : (
    <div className="h-[576px] px-12 pt-12">Loading authorization...</div>
  );
};
