import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

// This functions is given a short and surprising name by convention. It is
// also a dependency of some of the components in 'components/ui.'
// When using formatting libraries like `classnames` or `clsx`, it is common to
// wrap these calls and shorten them to `cn` because it is a utility that is
// used so frequently.
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
