import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import * as Sentry from "@sentry/browser";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import errorArt from "/error-art.svg";
import greenArc from "/green-arc.svg";
import rebootLogo from "/reboot-logo.svg";

export const Error = () => {
  const [error, setError] = useState<string>();
  const [searchParams, _setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryError = searchParams.get("error");

    if (queryError !== null) {
      try {
        setError(
          JSON.stringify(JSON.parse(decodeURIComponent(queryError)), null, 2)
        );
      } catch (error) {
        setError(queryError);
      }
    } else {
      setError("Undefined error");
    }
  }, []);

  if (error !== undefined) {
    Sentry.captureMessage(error);
  }

  return (
    <div className="relative w-screen h-screen">
      <div className="absolute left-0 h-screen p-12 z-40">
        <img src={rebootLogo} className="w-32 mb-8 mb-6" />
        <h1>Uh oh, an error occurred... </h1>
        <div>
          Go back to the{" "}
          <Link to="/" className="text-green-600">
            home
          </Link>{" "}
          page.
        </div>
        <Separator className="mt-20" />
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger>View details</AccordionTrigger>
            <AccordionContent>{error}</AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <img
        src={errorArt}
        className="absolute right-0 h-screen hidden lg:block object-cover"
      />
      <img
        src={greenArc}
        className="absolute bottom-0 right-0 block lg:hidden w-1/2 m-2"
      />
    </div>
  );
};
