// This file was copied and modified from:
// https://github.com/ory/elements/blob/main/examples/react-spa/src/Recovery.tsx
// Ory custom UI elements documentation:
// https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-ory-elements

import { RecoveryFlow, UpdateRecoveryFlowBody } from "@ory/client";
import { UserAuthCard } from "@ory/elements";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sdk, sdkError } from "./ory-sdk";

export const Recovery = () => {
  const [flow, setFlow] = useState<RecoveryFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getRecoveryFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, "/recovery");

  const createFlow = () => {
    sdk
      .createBrowserRecoveryFlow()
      .then(({ data: flow }) => {
        setSearchParams({ ["flow"]: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateRecoveryFlowBody) => {
    if (!flow) return navigate("/login", { replace: true });

    sdk
      .updateRecoveryFlow({ flow: flow.id, updateRecoveryFlowBody: body })
      .then(({ data: flow }) => {
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    const flowId = searchParams.get("flow");
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, []);

  return flow ? (
    <UserAuthCard
      flowType={"recovery"}
      flow={flow}
      className="py-0"
      additionalProps={{
        loginURL: {
          handler: () => {
            navigate(
              {
                pathname: "/login",
              },
              { replace: true }
            );
          },
        },
      }}
      onSubmit={({ body }) => submitFlow(body as UpdateRecoveryFlowBody)}
    />
  ) : (
    <div className="h-[576px] px-12 pt-12">Loading authorization...</div>
  );
};
