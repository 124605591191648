// @generated by protoc-gen-es v1.3.2
// @generated from file rbt/v1alpha1/tasks.proto (package rbt.v1alpha1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message rbt.v1alpha1.TaskId
 */
export const TaskId = proto3.makeMessageType(
  "rbt.v1alpha1.TaskId",
  () => [
    { no: 1, name: "state_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "task_uuid", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message rbt.v1alpha1.TaskInfo
 */
export const TaskInfo = proto3.makeMessageType(
  "rbt.v1alpha1.TaskInfo",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(TaskInfo_Status) },
    { no: 2, name: "task_id", kind: "message", T: TaskId },
    { no: 3, name: "method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "occurred_at", kind: "message", T: Timestamp },
    { no: 5, name: "scheduled_at", kind: "message", T: Timestamp },
    { no: 6, name: "iterations", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "num_runs_failed_recently", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from enum rbt.v1alpha1.TaskInfo.Status
 */
export const TaskInfo_Status = proto3.makeEnum(
  "rbt.v1alpha1.TaskInfo.Status",
  [
    {no: 0, name: "UNKNOWN"},
    {no: 1, name: "STARTED"},
    {no: 2, name: "SCHEDULED"},
    {no: 3, name: "SCHEDULED_RETRY"},
    {no: 4, name: "CANCELLED"},
    {no: 5, name: "COMPLETED"},
  ],
);

/**
 * @generated from message rbt.v1alpha1.WaitRequest
 */
export const WaitRequest = proto3.makeMessageType(
  "rbt.v1alpha1.WaitRequest",
  () => [
    { no: 1, name: "task_id", kind: "message", T: TaskId },
  ],
);

/**
 * @generated from message rbt.v1alpha1.TaskResponseOrError
 */
export const TaskResponseOrError = proto3.makeMessageType(
  "rbt.v1alpha1.TaskResponseOrError",
  () => [
    { no: 1, name: "response", kind: "message", T: Any, oneof: "response_or_error" },
    { no: 2, name: "error", kind: "message", T: Any, oneof: "response_or_error" },
  ],
);

/**
 * @generated from message rbt.v1alpha1.WaitResponse
 */
export const WaitResponse = proto3.makeMessageType(
  "rbt.v1alpha1.WaitResponse",
  () => [
    { no: 1, name: "response_or_error", kind: "message", T: TaskResponseOrError },
  ],
);

/**
 * @generated from message rbt.v1alpha1.ListTasksRequest
 */
export const ListTasksRequest = proto3.makeMessageType(
  "rbt.v1alpha1.ListTasksRequest",
  () => [
    { no: 1, name: "only_consensus_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message rbt.v1alpha1.ListTasksResponse
 */
export const ListTasksResponse = proto3.makeMessageType(
  "rbt.v1alpha1.ListTasksResponse",
  () => [
    { no: 1, name: "tasks", kind: "message", T: TaskInfo, repeated: true },
  ],
);

/**
 * @generated from message rbt.v1alpha1.CancelTaskRequest
 */
export const CancelTaskRequest = proto3.makeMessageType(
  "rbt.v1alpha1.CancelTaskRequest",
  () => [
    { no: 1, name: "task_id", kind: "message", T: TaskId },
  ],
);

/**
 * @generated from message rbt.v1alpha1.CancelTaskResponse
 */
export const CancelTaskResponse = proto3.makeMessageType(
  "rbt.v1alpha1.CancelTaskResponse",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CancelTaskResponse_Status) },
  ],
);

/**
 * @generated from enum rbt.v1alpha1.CancelTaskResponse.Status
 */
export const CancelTaskResponse_Status = proto3.makeEnum(
  "rbt.v1alpha1.CancelTaskResponse.Status",
  [
    {no: 0, name: "OK"},
    {no: 1, name: "NOT_FOUND"},
    {no: 2, name: "CANCELLING"},
  ],
);

/**
 * @generated from message rbt.v1alpha1.TaskCancelledError
 */
export const TaskCancelledError = proto3.makeMessageType(
  "rbt.v1alpha1.TaskCancelledError",
  [],
);

