// This file was copied and modified from:
// https://github.com/ory/elements/blob/main/examples/react-spa/src/Verification.tsx
// Ory custom UI elements documentation:
// https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-ory-elements

import { UpdateVerificationFlowBody, VerificationFlow } from "@ory/client";
import { UserAuthCard } from "@ory/elements";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthSession } from ".";
import { sdk, sdkError } from "./ory-sdk";

export const Verification = (): JSX.Element => {
  const [flow, setFlow] = useState<VerificationFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { rerunAuthToSession } = useAuthSession();

  const returnTo = searchParams.get("return_to");
  const flowId = searchParams.get("flow");

  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getVerificationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, "/verification", true);

  const createFlow = () => {
    sdk
      .createBrowserVerificationFlow({
        ...(returnTo && { returnTo: returnTo }),
      })
      .then(({ data: flow }) => {
        setSearchParams({ ["flow"]: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateVerificationFlowBody) => {
    if (!flow) return navigate("/verification", { replace: true });

    sdk
      .updateVerificationFlow({
        flow: flow.id,
        updateVerificationFlowBody: body,
      })
      .then(({ data: flow }) => {
        setFlow(flow);
        rerunAuthToSession();
        navigate("/", { replace: true });
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, []);

  return flow ? (
    <UserAuthCard
      flowType={"verification"}
      flow={flow}
      className="py-0"
      additionalProps={{
        signupURL: {
          handler: () => {
            navigate({ pathname: "/signup" }, { replace: true });
          },
        },
      }}
      onSubmit={({ body }) => submitFlow(body as UpdateVerificationFlowBody)}
    />
  ) : (
    <div className="h-[576px] px-12 pt-12">Loading authorization...</div>
  );
};
