// This file was copied and modified from:
// https://github.com/ory/elements/blob/main/examples/react-spa/src/Recovery.tsx
// Ory custom UI elements documentation:
// https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-ory-elements

import { RegistrationFlow, UpdateRegistrationFlowBody } from "@ory/client";
import { UserAuthCard } from "@ory/elements";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthSession } from ".";
import { sdk, sdkError } from "./ory-sdk";

export const Signup = () => {
  const [flow, setFlow] = useState<RegistrationFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { rerunAuthToSession } = useAuthSession();

  const returnTo = searchParams.get("return_to");
  const loginChallenge = searchParams.get("login_challenge");

  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getRegistrationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, "/signup", true);

  const createFlow = () => {
    sdk
      .createBrowserRegistrationFlow({
        ...(returnTo && { returnTo: returnTo }),
        ...(loginChallenge && { loginChallenge: loginChallenge }),
      })
      .then(({ data: flow }) => {
        setSearchParams({ ["flow"]: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateRegistrationFlowBody) => {
    if (!flow) return navigate("/signup", { replace: true });

    sdk
      .updateRegistrationFlow({
        flow: flow.id,
        updateRegistrationFlowBody: body,
      })
      .then(({ data }) => {
        if ("continue_with" in data) {
          for (const cw of data.continue_with ?? []) {
            if (cw.action === "show_verification_ui") {
              const search = new URLSearchParams();
              search.set("flow", cw.flow.id);
              navigate(
                {
                  pathname: "/verification",
                  search: search.toString(),
                },
                { replace: true }
              );
              return;
            }
          }
        }

        rerunAuthToSession();
        navigate("/", { replace: true });
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    const flowId = searchParams.get("flow");
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, [navigate]);

  return flow ? (
    <UserAuthCard
      flowType={"registration"}
      flow={flow}
      className="py-0"
      additionalProps={{
        loginURL: {
          handler: () => {
            const search = new URLSearchParams();
            flow.return_to && search.set("return_to", flow.return_to);
            flow.oauth2_login_challenge &&
              search.set("login_challenge", flow.oauth2_login_challenge);
            navigate(
              { pathname: "/login", search: search.toString() },
              { replace: true }
            );
          },
        },
      }}
      includeScripts={true}
      onSubmit={({ body }) => submitFlow(body as UpdateRegistrationFlowBody)}
    />
  ) : (
    <div className="h-[576px] px-12 pt-12">Loading authorization...</div>
  );
};
