import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

interface FadeInProps {
  children: React.ReactNode;
  delayMilliseconds: number;
  durationMilliseconds: number;
}

export const FadeIn = ({
  children,
  delayMilliseconds = 500,
  durationMilliseconds = 500,
}: FadeInProps) => {
  // TODO(riley): do this in pure CSS. JS isn't needed for this transition.

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, delayMilliseconds);
  }, []);

  return (
    <div
      className={cn(
        `transition-opacity ease-in duration-${durationMilliseconds}`,
        loading ? "opacity-0" : "opacity-100"
      )}
    >
      {children}
    </div>
  );
};

FadeIn.defaultProps = {
  delayMilliseconds: 500,
  durationMilliseconds: 500,
};
